<template>
  <div class="login-page-wrapper">
    <div class="permission-title-bar-wrap" v-if="!sky">
        <p class="language" @click="$toENSite()">English</p>
      <el-link style="margin-right:50px;" target="_break" href="http://www.skysys.cn/">官方网站</el-link>
    </div>
    <form-container :logo="logo" @oView="goSignViewRoute">
      <div>
        <transition name="component-fade" mode="out-in">
          <component :is="view" ref="SyncLoginForm" :get-data="getFormInformation" :history="history" />
        </transition>
        <el-form>
          <el-form-item v-if="view !== 'WeChatCodeLogin'">
            <el-button type="primary" style="font-size: 14px; color: #fff !important" @click="login" @keyup.enter.native="login"> 登 录 </el-button>
          </el-form-item>
          <el-form-item class="tool-bar">
            <div class="form-item-container">
              <!-- :class="{ 'hidden-wechat-code': mode !== 2 }" -->
              <el-link :class="{ 'hidden-wechat-code': sky }" :underline="false" target="_break" @click="handleWeChatLogin">
                <img src="@/assets/images/login/weChat.png" alt="" />
                微信登录
              </el-link>
              <el-link :underline="false">
                <SkyLink path="/register" text="立即注册" />
              </el-link>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </form-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { developuCache, getuCache, recycleuCache, setAuthorization, setToken } from '@/utils/auth.js';
import FormContainer from '@/components/userContainer';
import AccountLoginForm from './components/AccountLoginForm.vue';
import PhoneLoginForm from './components/PhoneLoginForm.vue';
// import WeChatCodeLogin from './components/WeChatCodeLogin.vue';
import statusInclude from '@/utils/statusCode.js';
import Cookies from 'js-cookie';
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;

const _window_name = 'window_wechat_code';
const url_group = ['https://101.132.121.145'];

export default {
  name: 'Login',
  components: {
    FormContainer,
    AccountLoginForm,
    PhoneLoginForm,
    // WeChatCodeLogin,
  },
  data() {
    const viewComponents = {
      0: 'AccountLoginForm',
      1: 'PhoneLoginForm',
      2: 'AccountLoginForm',
    };
    const viewRoutes = {
      0: '/login',
      1: '/login:1',
      2: '/login:2',
    };
    return {
      mode: -1,
      cacheKey: '',
      isRemember: false,
      showPassword: false,
      loginType: 'account',
      inputType: 'password',
      view: 'AccountLoginForm',
      viewComponents,
      viewRoutes,
      timer: null,
      wechat_code_window: null,
      history: {},
      rules: {
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['showLogo', 'logo', 'title']),

    route() {
      return this.$route.path;
    },

    isHiddenPassword() {
      return !this.showPassword && this.loginType === 'account';
    },

    // 微信会掉参数
    wechatAuthCode() {
      return `weChatCode${new Date().getTime() + Math.random()}`;
    },

    src() {
      return `${server}/auth/api/weChat/login?callback=${this.wechatAuthCode}`;
    },

    window_is_closed() {
      return this.wechat_code_window && this.wechat_code_window.closed;
    },

    sky() {
      return url_group.findIndex((item) => window.location.href.split('#')[0].indexOf(item) > -1) > -1;
    },
  },

  watch: {
    route(route) {
      this.loadLoginView(this.formatLoginParams(route));
    },
    window_is_closed(flag) {
      console.log(flag);
    },
  },

  created() {
    this.loadLoginView(this.formatLoginParams(this.route));

    // 获取用户加密公钥
    // this.$store.dispatch('auth/getPubKeys').then((response) => {
    //   console.log(response);
    // });

    const info = getuCache();
    if (info) {
      const { username, password = '', isRemember = false } = JSON.parse(info);
      this.history = {
        username,
        password,
        isRemember,
      };
    }
  },
  mounted() {
    //监听键盘按下事件 ‘回车登录’
    window.addEventListener('keydown', this.keyDown);
  },

  methods: {
    formatLoginParams(route) {
      if (route.indexOf('1') > -1) return 1;
      if (route.indexOf('2') > -1) return 2;
      return 0;
    },

    eyeClick(flag) {
      this.showPassword = flag;
      if (flag) {
        this.inputType = 'text';
      } else {
        this.inputType = 'password';
      }
    },

    goSignViewRoute(mode) {
      this.$router.push(this.viewRoutes[mode].toString());
    },

    // 加载不同类型登录组件
    loadLoginView(component) {
      console.log(component);
      this.mode = component;
      this.view = this.viewComponents[component];
    },

    // 获取用户信息
    getFormInformation(user) {
      console.log(user);
    },

    //会车登录
    keyDown(e) {
      // 如果是回车则执行登录方法
      e.keyCode === 13 && this.login();
    },

    /**
     * @description 是否缓存用户账号密码
     * @return void
     */
    handleDevelopUserInformation({ isRemember, username, password }) {
      this.view === 'AccountLoginForm' && isRemember
        ? developuCache({
            isRemember,
            username,
            password,
          })
        : recycleuCache();
    },

    handleWeChatLogin() {
      const url = this.src;

      const callback = url.substring(url.indexOf('=') + 1);

      function openwindow(url, name, iWidth, iHeight) {
        var url; //转向网页的地址;
        var name; //网页名称，可为空;
        var iWidth; //弹出窗口的宽度;
        var iHeight; //弹出窗口的高度;
        //window.screen.height获得屏幕的高，window.screen.width获得屏幕的宽
        var iTop = (window.screen.height - 30 - iHeight) / 2; //获得窗口的垂直位置;
        var iLeft = (window.screen.width - 10 - iWidth) / 2; //获得窗口的水平位置;
        return window.open(url, name, 'height=' + iHeight + ',innerHeight=' + iHeight + ',width=' + iWidth + ',innerWidth=' + iWidth + ',top=' + iTop + ',left=' + iLeft + ',toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no');
      }

      this.cacheKey = callback;
      //打开微信code窗口
      this.wechat_code_window = openwindow(url, _window_name, 500, 540);
      this.weChatLogin(callback);
    },

    clearHeartbeatInterval(key) {
      Cookies.remove(key);
      window.clearInterval(this.timer);
      this.timer = null;
    },

    handleWindowClosed() {
      return this.wechat_code_window && this.wechat_code_window.closed;
    },

    // 登录
    login() {
      this.$refs.SyncLoginForm.submit()
        .then((loginInformation) => {
          const { account: username, password, isRemember } = loginInformation;
          this.$store
            .dispatch('user/login', { name: username, password, loginMode: this.view === 'AccountLoginForm' ? 1 : 2 })
            .then((response) => {
              const { code, reason } = response;
              if (!statusInclude(code)) return this.$message.error(reason);
              const { Authorization, refreshToken } = response;
              setToken(refreshToken); //储存refreshToken
              setAuthorization(Authorization); //缓存Authorization
              this.handleDevelopUserInformation({ isRemember, username, password }); //缓存用户信息
              this.$router.push('/main');
            })
            .catch((error) => {
              this.$message.error(error.reason);
            });
        })
        .catch((err) => {
          throw new Error(err);
        });
    },

    //微信登录
    weChatLogin(key) {
      console.log(key);
      this.timer = setInterval(() => {
        const response = this.heartbeatDetection(key);
        if (response) {
          //得到鉴权信息时
          this.clearHeartbeatInterval(key);

          //关闭微信二维码窗口
          if (this.wechat_code_window) this.wechat_code_window.close();

          //储存鉴权信息
          const { Authorization, refreshToken } = response;
          setToken(refreshToken); //储存refreshToken
          setAuthorization(Authorization); //缓存Authorization

          //跳转到首页
          this.$router.push('/main');
        } else {
          //查询窗口是否关闭
          this.handleWindowClosed() && this.clearHeartbeatInterval(key);
        }
      }, 500);
    },

    heartbeatDetection(key) {
      try {
        const response = Cookies.get(key);
        return response && JSON.parse(response);
      } catch (error) {
        return false;
      }
    },
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyDown, false);
    // 页面即将销毁时清理心跳
    this.clearHeartbeatInterval(this.cacheKey);
  },
};
</script>

<style lang="scss" scoped>
.login-page-wrapper {
  height: 100vh;
  background-image: $-main-login-page-background-image;
  background-repeat: no-repeat;
  background-size: cover;

  .component-fade-enter-active,
  .component-fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .component-fade-enter,
  .component-fade-leave-to {
    opacity: 0;
  }

  .el-link {
    &.wechat-code {
      visibility: hidden;
    }
    &.hidden-wechat-code {
      visibility: hidden;
    }

    img {
      width: 14px;
    }
  }

  /deep/ .el-form {
    .el-button {
      background: #409eff;

      span {
        color: #fff;
      }
    }
  }
}
</style>

<style lang="scss" scoped>

.language{
  color: white;
  cursor: pointer;
  &:hover{
    opacity: 0.85;
  }
}
@media screen and (max-device-width: 420px) {
  .login-page-wrapper {
  }
}
</style>
