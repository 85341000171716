<template>
  <div class="account-login-form">
    <el-form ref="LoginForm" :model="loginForm" :rules="rules">
      <el-form-item label="账号/手机号" prop="account">
        <el-input v-model="loginForm.account" placeholder="账号/手机号" />
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="loginForm.password" :type="showPassword ? 'text' : 'password'" placeholder="密码">
          <template slot="suffix">
            <i v-if="showPassword" class="el-icon-view el-input__icon" @click="showPassword = false" />
            <img v-else class="eye" src="@/assets/images/login/no_view.png" @click="showPassword = true" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <div class="form-item-container">
          <el-checkbox v-model="loginForm.isRemember"> 记住密码 </el-checkbox>
          <el-link :underline="false">
            <SkyLink path="/reset-password" text="忘记密码" icon="el-icon-lock" />
          </el-link>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { validateAccount } from '@/utils/validate.js';

export default {
  name: 'AccountLoginForm',
  props: {
    getData: {
      type: Function,
      default: () => {},
    },
    history: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showPassword: false,
      loginForm: {
        account: '',
        password: '',
        isRemember: false,
      },
      rules: {
        account: [{ required: true, validator: validateAccount, trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
    };
  },
  created() {
    const { username, password, isRemember } = this.history;
    this.loginForm.account = username;
    this.loginForm.password = password;
    this.loginForm.isRemember = isRemember;
  },
  methods: {
    submit() {
      return new Promise((resolve, reject) => {
        this.$refs.LoginForm.validate((valid) => {
          if (valid) {
            resolve(this.loginForm);
          } else {
            return reject(new Error('用户信息不完整'));
          }
          return '';
        });
      });
    },
  },
};
</script>
