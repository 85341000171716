<template>
  <div class="phone-login-form">
    <el-form ref="uPhoneLoginForm" :model="loginForm" :rules="rules">
      <el-form-item label="手机号" prop="account">
        <el-input v-model="loginForm.account" placeholder="手机号" />
      </el-form-item>
      <el-form-item label="验证码" prop="password">
        <el-input v-model="loginForm.password" placeholder="验证码">
          <template slot="append">
            <SkyAppendButton :phone="loginForm.account" template-type="login" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-link :underline="false" style="float: right">
          <SkyLink path="/reset-password" text="忘记密码" icon="el-icon-lock" />
        </el-link>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { validatePhone, validateVerificationCode } from '@/utils/validate.js';

export default {
  name: 'PhoneLoginForm',
  props: {
    getData: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loginForm: {
        account: '',
        password: '',
      },
      rules: {
        account: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        password: [{ required: true, validator: validateVerificationCode, trigger: 'blur' }],
      },
    };
  },
  methods: {
    submit() {
      return new Promise((resolve, reject) => {
        this.$refs.uPhoneLoginForm.validate((valid) => {
          if (valid) {
            resolve(this.loginForm);
          } else {
            return reject(new Error('用户信息不完整'));
          }
          return '';
        });
      });
    },
  },
};
</script>
